import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './plugin/i18n'
import Analytic from './plugin/analytic'
import 'bootstrap';

const formatter                      = new Intl.NumberFormat();
const translate                      = new i18n({store});
const analytic                       = new Analytic();
const app                            = createApp(App);
app.config.globalProperties.$filters = {
  money(value) {
    if (typeof value !== 'number') {
      return value;
    }
    return formatter.format(value);
  }
};
app.use(translate).use(analytic).use(store).use(router).mount('#app');

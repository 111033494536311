<template>
  <div class="form-group " style="width: 100%">
    <input type="text" @focus="focus" @blur="blur" class="form-control custom-input" placeholder="..." v-model="selectedText"
           @input="debounceSearch">
    <ul :class="{'show': show || showForce}" @mouseenter="showForce = true" @mouseleave="showForce = false">
      <li v-for="(item, index) in result" :key="index" @click="select(item)">
        {{item.Description}}
      </li>
    </ul>
  </div>
</template>

<style scoped>
  ul {
    position   : absolute;
    background : white;
    width      : 100%;
    max-height : 150px;
    overflow-y : scroll;
    opacity    : 0;
    z-index    : -11;
    transition : z-index 0.1s;
    cursor : pointer;
  }

  .show {
    z-index : 1111;
    opacity : 1;
  }
</style>

<script>
  export default {
    name   : 'SearchSelect',
    events : ['change'],
    props  : ['url', 'preload', 'value', 'search'],
    data() {
      return {
        debounce    : undefined,
        show        : false,
        showForce        : false,
        result      : [],
        selected    : undefined,
        selectedText: ''
      }
    },
    mounted() {
      if (this.preload) {
        this.load();
      }
      if (this.value) {
        this.selected     = this.value;
        this.selectedText = this.value.Description;
      }
    },
    methods: {
      focus() {
        this.show = true;
        if (this.result.length === 0) {
          this.load();
        }
      },
      blur() {
        this.show = false
      },
      load(query) {
        this.search(query).then(result => {
          this.result = result
        });
      },
      select(item) {
        this.show = false;
        this.showForce = false;

        this.selected     = item;
        this.selectedText = item.Description;
        this.$emit('change', item);
      },
      debounceSearch(event) {
        clearTimeout(this.debounce);

        this.debounce = setTimeout(() => {
          this.load(event.target.value)
        }, 600)
      }
    }
  }
</script>

<template>
  <div class="catalog-wrapper wrapper">
    <div class="custom-header category-header bg-cover" v-if="categoryImage"
         :style="'background-image: url(' + categoryImage + ');'">
      <!--      <h2 class="h1 mobile-hidden">{{$dynamic(categoryObject, categoryName, 'name')}}</h2>-->
    </div>
    <div class="category-header custom-header min-header" v-else>
      <!--      <h2 class="h1 mobile-hidden">{{$dynamic(categoryObject, categoryName, 'name')}}</h2>-->
    </div>
    <div class="cat-title">
      <h1>{{ $dynamic(categoryObject, categoryName, 'name') }}</h1>
    </div>
    <div class="sorting-panel" v-if="tags && tags.length">
      <div class="custom-label">
        {{ $static('catalog.title', 'Оберіть своє:') }}
      </div>
      <div class="sort-list">
        <div class="sorting-checkbox">
          <input id="all" type="checkbox" v-model="all_tags" value="1">
          <label for="all">
            <span class="text">{{ $static('options.all', 'Усі') }}</span>
          </label>
        </div>

        <div class="sorting-checkbox" v-for="(op, index) in tags" :key="index">
          <input v-if="op" :id="op.id" type="checkbox" v-model="optionSelect" :value="op.id">
          <label v-if="op" :for="op.id">
            <span class="pick" v-if="op.image" :style="getImage(op)"></span>
            <span class="pick" v-else-if="op.color" :style="getColor(op)"></span>
            <span class="text">
              <span class="custom-option-name" v-if="op.name">{{ $dynamic(op, op.name, 'name') }}</span>
              <span class="custom-option-price">
                <span class="price" v-if="op.price"> {{ $filters.money(op.price) }} {{
                    $store.getters.getSettings.symbol
                  }}</span>
                <span class="price price-old" v-if="op.price_old"> {{ $filters.money(op.price_old) }} {{
                    $store.getters.getSettings.symbol
                  }}</span>
              </span>
            </span>
          </label>
        </div>
      </div>
    </div>
    <div class="catalog-grid-holder">
      <ul class="catalog-grid">
        <li class="catalog-item" v-for="(item, index) in items" :key="index">
          <div class="item-holder">
            <div class="product-label label-new" v-if="isHot(item)">
              <span class="label-text">New</span>
            </div>
            <div class="product-label label-sale" v-if="item.price_old">
              <div class="tile_label tile_sale">-{{ getDiscount(item) }}%</div>
            </div>
            <router-link :to="{ name: 'Item', params: { category: categoryObject.info.url, id: item.id } }"
                         class="image bg-cover" :style="`background-image: url(${getItemImage(item)});`"></router-link>
            <div class="item-content">
              <div class="product-name">
                <router-link :to="{ name: 'Item', params: { category: categoryObject.info.url, id: item.id } }">{{
                    $dynamic(
                        item,
                        item.title,
                        'title'
                    )
                  }}
                </router-link>

              </div>
            </div>
            <div class="options">
              <div class="price-box">
                <div class="price-element" v-if="item.price > 0"> {{ $filters.money(item.price) }} {{
                    $store.getters.getSettings.symbol
                  }}
                </div>
                <div class="price-old" v-if="item.price_old"> {{ $filters.money(item.price_old) }} {{
                    $store.getters.getSettings.symbol
                  }}
                </div>
              </div>
              <div class="right-options">
                <router-link :to="{ name: 'Item', params: { category: categoryObject.info.url, id: item.id } }"
                             class="default-btn">
                  <span class="btn-text">{{ $static('catalog.info', 'Детальніше') }}</span>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M12.5 10L12.8536 9.64645L13.2071 10L12.8536 10.3536L12.5 10ZM7.85355 4.64645L12.8536 9.64645L12.1464 10.3536L7.14645 5.35355L7.85355 4.64645ZM12.8536 10.3536L7.85355 15.3536L7.14645 14.6464L12.1464 9.64645L12.8536 10.3536Z"
                        fill="white"/>
                  </svg>
                </router-link>
              </div>
            </div>
          </div>
        </li>
        <div ref="loadMore"></div>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name : 'Catalog',
  props: ['category'],
  data() {
    return {
      optionSelect: [],
      items       : [],
      load        : false,
      page        : 1,
      perPage     : 12,
      total       : 0,
      more        : true,
      checkLoader : undefined,
      loadBefore  : 0
    }
  },
  beforeUnmount() {
    window.document.removeEventListener('scroll', this.onscroll);
  },
  updated() {
    if (this.more) {
      this.loadBefore = this.checkLoader.offsetTop;
    } else {
      let body        = document.body, html = document.documentElement;
      this.loadBefore = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight) + 1000;
    }
  },
  mounted() {
    this.checkLoader = this.$refs.loadMore;
    window.document.addEventListener('scroll', this.onscroll);

    this.getItems();
    this.setSeo(this.categoryObject);

    this.$ViewCategory(this.categoryObject)
  },
  methods : {
    onscroll(e) {
      if (document.documentElement.scrollTop + window.innerHeight > this.loadBefore && this.more) {
        this.page += 1;
        this.getItems();
      }
    },
    getImage(item) {
      return `background-image: url(${item.image});`
    },
    getColor(item) {
      return `background-color: ${item.color};`
    },
    isHot(item) {
      return item.advanced && item.advanced.hot
    },
    getItems(clear) {
      if (this.load) {
        return
      }
      this.load = true;
      if (clear) {
        this.page = 1;
        this.more = true;
      }
      let filter = {page: this.page, perPage: this.perPage};
      if (this.categoryName) {
        filter.category = this.categoryObject.id
      } else {
        filter.url = this.category
      }

      if (this.optionSelect.length) {
        filter.tags = this.optionSelect.map(id => this.tags.find(o => o.id === id)).map(x => x ? x.id : x);
      }
      fetch('/public/item/filter', {
        method : 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body   : JSON.stringify({filter: filter, sortBy: 'sort'})
      }).then(response => response.json()).then(data => {
        this.load = false;
        if (clear) {
          this.items = data.result;
        } else {
          this.items.push(...data.result);
        }
        this.page  = data.page;
        this.total = data.total;
        if (this.items.length === this.total) {
          this.more = false
        }
      }).catch(e => {
        this.load = false;
      })
    },
    getItemImage(item) {
      if (item.preview && item.preview.includes('https://')) {
        return item.preview
      }
      return item.preview ? `${window.location.protocol}//${window.location.host}${item.preview}` : ''
    },
    getDiscount(item) {
      return item.preview ? (1 - Math.max(item.price / item.price_old, 0)) * 100 | 0 : ''
    },
    setSeo(val) {
      if (val && val.info) {
        let title = val.info.title ? val.info.title : this.$dynamic(this.categoryObject, this.categoryName, 'name');

        document.querySelector('title').innerText = title;
        document.querySelector('meta[property="og:title"]').setAttribute('content', title);

        let description = val.info.description;
        document.querySelector('meta[name="description"]').setAttribute('content', description ? description : '');
        document.querySelector('meta[property="og:description"]').setAttribute('content', description ? description : '');
        let image = val.image ? `${window.location.protocol}//${window.location.host}${val.image}` : '';
        document.querySelector('meta[property="og:image"]').setAttribute('content', image);
      }
    }
  },
  computed: {
    // tags() {
    //   return this.$store.getters.getTags.filter(x => x.info ? !x.info.hidden : true)
    // },
    categoryTags(){
      return this.categoryObject?.info?.tags
    },
    tags() {
      return this.categoryTags ?
          this.categoryTags.map(id => this.storeTags.find(i => i.id === id)).filter(i => i && i.id)
          : []
    },
    storeTags(){
      return this.$store.getters.getTags
    },
    all_tags: {
      get() {
        return this.optionSelect.length === 0
      },
      set() {
        this.optionSelect = []
      }
    },
    categoryObject() {
      return this.$store.getters.getCategories.find(i => i.info.url === this.category)
    },
    categoryName() {
      return this.categoryObject ? this.categoryObject.name : ''
    },
    categoryImage() {
      return this.categoryObject && this.categoryObject.image ? this.categoryObject.image : ''
    }
  },
  watch   : {
    category() {
      this.$ViewCategory(this.categoryObject)

      this.getItems(true)
    },
    optionSelect() {
      this.getItems(true)
    },
    categoryObject(val) {
      this.setSeo(val)
    }
  }
}
</script>

<style scoped></style>